import Button from "../../components/button/Button";

const NotFound = () => (
  <div className="space">
    <div className="container">
      <div className="error-content text-center">
        <h2 className="error-number">4<span className="text-theme">0</span>4</h2>
        <h3 className="error-title">Ooops, Page Not Found</h3>
        <p className="error-text">We’re sorry but we can’t seem to find the page you requested. This might be because you have typed the web address incorrectly.</p>
        <Button path="/">
          Back To Home
        </Button>
      </div>
    </div>
  </div>
);

export default NotFound;