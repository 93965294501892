import React from "react";



function Maintenance() {
  return (
    <div className="h-100 text-center justify-content-center d-flex align-items-center">
      <div className="font-os-smbold">
        <div className="col-12 text-center">
          <img
            src="assets/cg_logo.png"
            width="100%"
            maxHeight="200px"
            minHeight="120px"
            alt="CakeGarden"
          />
        </div>
        <div style={{ color: "white", fontSize: 64 }}>COMING SOON...</div>
        <div style={{ color: "white", fontSize: 22 }}>CakeGardenla &copy; 2024</div>
      </div>
    </div>
  );
}

export default Maintenance;
