import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTopPage from "../utils/scrolltotop";

import NotFound from "../app/pages/notfound";
import Layout from "../app/pages/layout";
import About from "../app/pages/about";
import Contact from "../app/pages/contact";
import Home from "../app/pages/home";
import Menu from "../app/pages/menu";
import Service from "../app/pages/service";
import MenuItems from "../app/pages/menu/items";
import Maintenance from "../app/pages/maintenance";

const AppRouters = () => (
  <Fragment>
    <ScrollToTopPage>
      <Routes>
        <Route path="/" element={<Maintenance />}></Route>
        {/* <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/menu" element={<Menu />}></Route>
          <Route path="/menu/:name" element={<MenuItems />}></Route>
          <Route path="/service" element={<Service />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Route> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTopPage>
  </Fragment>
);

export default AppRouters;
