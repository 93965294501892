import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/main-style.scss';
import { BrowserRouter } from "react-router-dom";
import AppRouters from "./routes/AppRouter";

function App() {
  return (
    <BrowserRouter>
      <AppRouters />
    </BrowserRouter>
  );
}

export default App;
